import { WbButton, WbGrid, WbGridCol, WbGridRow, WbIcon } from '@workbench/react';
import React from 'react';
import { Search } from './Search';
import { white } from '@mercedes-benz/mui5-theme';
import { makeStyles } from 'tss-react/mui';
import { Theme, Typography, useTheme } from '@mui/material';
import { GridColumns } from '@workbench/core';

const useStyles = makeStyles()((theme: Theme) => ({
    gridRow: {
        gridGap: `${theme.spacing(0)} !important`,
    },
    textField: {
        backgroundColor: white,
    },
    searchButton: {
        height: '100%',
    },
}));

interface SearchFieldProps {
    inputRef?: React.RefObject<HTMLElement>;
    label?: string;
    mq1?: GridColumns | 'hide' | undefined;
    mq2?: GridColumns | 'hide' | undefined;
    mq3?: GridColumns | 'hide' | undefined;
    mq4?: GridColumns | 'hide' | undefined;
    mq5?: GridColumns | 'hide' | undefined;
    mq6?: GridColumns | 'hide' | undefined;
    disableSearch?: boolean;
    errorMessage?: string;
    delay?: number;
    maxLength?: number;
    helperText?: string;
    onSearchTextChange: (text: string) => void;
    onSearchButtonClick: () => void;
    isSearching: boolean;
}

const SearchField = ({
    inputRef,
    label,
    mq1,
    mq2,
    mq3,
    mq4,
    mq5,
    mq6,
    disableSearch,
    errorMessage,
    delay,
    maxLength,
    helperText,
    onSearchTextChange,
    onSearchButtonClick,
    isSearching,
}: SearchFieldProps) => {
    const { classes } = useStyles();
    const theme = useTheme();

    return (
        <WbGrid>
            <WbGridRow className={classes.gridRow}>
                <WbGridCol mq1={mq1} mq2={mq2} mq3={mq3} mq4={mq4} mq5={mq5} mq6={mq6}>
                    <Search
                        onChangeValue={(e) => onSearchTextChange && onSearchTextChange(e)}
                        variant="filled"
                        label={label}
                        fullWidth
                        className={classes.textField}
                        error={errorMessage !== undefined}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        inputRef={inputRef}
                        delay={delay}
                        maxLength={maxLength}
                    />
                </WbGridCol>
                <WbGridCol mq1={1}>
                    <WbButton
                        variant="primary"
                        size="l"
                        className={classes.searchButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSearchButtonClick && onSearchButtonClick();
                        }}
                        loading={isSearching ? isSearching : undefined}
                        disabled={isSearching || disableSearch ? true : undefined}
                    >
                        <WbIcon name="bds/search/24" />
                    </WbButton>
                </WbGridCol>
            </WbGridRow>
            {errorMessage && (
                <WbGridRow className={classes.gridRow}>
                    <WbGridCol mq1={mq1} mq2={mq2} mq3={mq3} mq4={mq4} mq5={mq5} mq6={mq6}>
                        <Typography color="error" variant="body2">
                            {errorMessage}
                        </Typography>
                    </WbGridCol>
                </WbGridRow>
            )}
            {helperText && !errorMessage && (
                <WbGridRow className={classes.gridRow}>
                    <WbGridCol mq1={mq1} mq2={mq2} mq3={mq3} mq4={mq4} mq5={mq5} mq6={mq6}>
                        <Typography color={theme.palette.common.white} variant="body2">
                            {helperText}
                        </Typography>
                    </WbGridCol>
                </WbGridRow>
            )}
        </WbGrid>
    );
};

export default SearchField;
