//Icons
import greaseIcon from '../assets/icons/grease_48.svg';
import brakeFluidIcon from '../assets/icons/brakeFluid_48.svg';
import fuelIcon from '../assets/icons/fuel_48.svg';
import hydraulicOilIcon from '../assets/icons/hydraulicOil_48.svg';
import stearingGearIcon from '../assets/icons/stearingGear_48.svg';
import adBlueIcon from '../assets/icons/adBlue_48.svg';
import engineOilIcon from '../assets/icons/engineOil_48.svg';
import washerFluidIcon from '../assets/icons/washerFluid_48.svg';
import refrigeratorOilIcon from '../assets/icons/refrigeratorOil_48.svg';
import transmissionIcon from '../assets/icons/transmission_48.svg';
import preservationIcon from '../assets/icons/preservation_48.svg';
import coolantIcon from '../assets/icons/coolant_48.svg';
import refrigerantIcon from '../assets/icons/refrigerant_48.svg';

// Category header images
import adBlueHeader from '../assets/image/adBlue.jpg';
import oilDropHeader from '../assets/image/oilDrop.jpg';
import gearHeader from '../assets/image/gear.jpg';
import CoolantHeader from '../assets/image/coolant.jpg';
import engineHeader from '../assets/image/engine.jpg';
import brakeFluidHeader from '../assets/image/brakeFluid.jpg';

// API
export const BASE_URL = '/api/v1/';
// export const BASE_URL = 'http://localhost:8080/api/v1/';
// export const BASE_URL = 'https://bevo-dev.app.corpintra.net/api/v1/';

// Categories
export type CategoryType = {
    icon: string;
    image: string;
    description: CategoryDescription;
    link: string;
};

export enum CategoryDescription {
    ENGINEOIL = 'EngineOil',
    BRAKEFLUID = 'BrakeFluid',
    FUEL = 'Fuel',
    GEAROIL = 'GearOil',
    GREASE = 'Grease',
    HYDRAULICFLUID = 'HydraulicFluid',
    PRESERVATIONAGENT = 'PreservationAgent',
    REFRIGERATOROIL = 'RefrigeratorOil',
    STEERINGGEAROIL = 'SteeringGearOil',
    ADBLUE = 'AdBlue',
    COOLANT = 'Coolant',
    REFRIGERANT = 'Refrigerant',
    WINDSHIELD = 'Windshield',
}

export const CATEGORY_PROPS: CategoryType[] = [
    {
        icon: engineOilIcon,
        image: engineHeader,
        description: CategoryDescription.ENGINEOIL,
        link: '/engine-oil',
    },
    {
        icon: brakeFluidIcon,
        image: brakeFluidHeader,
        description: CategoryDescription.BRAKEFLUID,
        link: '/brake-fluid',
    },
    {
        icon: fuelIcon,
        image: oilDropHeader,
        description: CategoryDescription.FUEL,
        link: '/fuel',
    },
    {
        icon: transmissionIcon,
        image: gearHeader,
        description: CategoryDescription.GEAROIL,
        link: '/gear-oil',
    },
    {
        icon: greaseIcon,
        image: oilDropHeader,
        description: CategoryDescription.GREASE,
        link: '/grease',
    },
    {
        icon: hydraulicOilIcon,
        image: oilDropHeader,
        description: CategoryDescription.HYDRAULICFLUID,
        link: '/hydraulic-fluid',
    },
    {
        icon: preservationIcon,
        image: oilDropHeader,
        description: CategoryDescription.PRESERVATIONAGENT,
        link: '/preservation-agent',
    },
    {
        icon: refrigeratorOilIcon,
        image: oilDropHeader,
        description: CategoryDescription.REFRIGERATOROIL,
        link: '/compressor-oil',
    },
    {
        icon: stearingGearIcon,
        image: oilDropHeader,
        description: CategoryDescription.STEERINGGEAROIL,
        link: '/stearing-gear-oil',
    },
    {
        icon: adBlueIcon,
        image: adBlueHeader,
        description: CategoryDescription.ADBLUE,
        link: '/NOx-reducing-agent',
    },
    {
        icon: coolantIcon,
        image: CoolantHeader,
        description: CategoryDescription.COOLANT,
        link: '/coolant',
    },
    {
        icon: refrigerantIcon,
        image: oilDropHeader,
        description: CategoryDescription.REFRIGERANT,
        link: '/refrigerant',
    },
    {
        icon: washerFluidIcon,
        image: oilDropHeader,
        description: CategoryDescription.WINDSHIELD,
        link: '/windshield-washer-fluid-concentrate',
    },
];

// Sorted categories
// export const CATEGORY_PROPS = CATEGORY_PROPS_UNSORTED.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1);

export const TABLE_HEADER: {
    [key: string]: { key: string; value: string };
} = {
    productName: { key: 'productName', value: 'ProductName' },
    description: { key: 'description', value: 'Principal' },
    type30: { key: 'type30', value: '30' },
    type40: { key: 'type40', value: '40' },
    type80: { key: 'type80', value: '80' },
    type90: { key: 'type90', value: '90' },
    type0W: { key: 'type0W', value: '0W' },
    type0W20: { key: 'type0W20', value: '0W-20' },
    type0W30: { key: 'type0W30', value: '0W-30' },
    type0W40: { key: 'type0W40', value: '0W-40' },
    type5W20: { key: 'type5W20', value: '5W-20' },
    type5W30: { key: 'type5W30', value: '5W-30' },
    type5W40: { key: 'type5W40', value: '5W-40' },
    type5W50: { key: 'type5W50', value: '5W-50' },
    type10W30: { key: 'type10W30', value: '10W-30' },
    type10W40: { key: 'type10W40', value: '10W-40' },
    type10W60: { key: 'type10W60', value: '10W-60' },
    type15W40: { key: 'type15W40', value: '15W-40' },
    type20W50: { key: 'type20W50', value: '20W-50' },
    type75W: { key: 'type75W', value: '75W' },
    type75W80: { key: 'type75W80', value: '75W-80' },
    type75W85: { key: 'type75W85', value: '75W-85' },
    type75W90: { key: 'type75W90', value: '75W-90' },
    type80W: { key: 'type80W', value: '80W' },
    type80W90: { key: 'type80W90', value: '80W-90' },
    type80W85: { key: 'type80W85', value: '80W-85' },
    type85W90: { key: 'type85W90', value: '85W-90' },
};

export const MERCEDES = 'Mercedes';
