import { WbGrid, WbGridRow, WbGridCol, WbHeading } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../common/i18n/useTranslation';
import SearchResultNotification from './SearchResultNotification';
import SearchResultTable from './SearchResultTable';
import { FuelApprovalDto } from '../../../api/models/FuelApprovalDto';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        color: theme.palette.common.white,
        gridGap: `${theme.spacing(0)} !important`,
    },
    headerRow: {
        gridGap: `${theme.spacing(0)} !important`,
    },
}));

interface SearchResultProps {
    hasData?: boolean | null;
    fuelApproval?: FuelApprovalDto | string;
}

const SearchResult = ({ hasData, fuelApproval }: SearchResultProps) => {
    const translate = useTranslation();
    const { classes } = useStyles();

    return (
        <>
            {hasData !== undefined && hasData !== null && (
                <WbGrid className={classes.container}>
                    <WbGridRow>
                        <WbGridCol>
                            <WbHeading size="m">
                                <strong>{translate('FluidFinderFinSearchResult')}</strong>
                            </WbHeading>
                        </WbGridCol>
                    </WbGridRow>
                    <WbGridRow>
                        <WbGridCol mq1={12} mq2={12} mq3={10} mq4={10} mq5={10} mq6={10}>
                            <SearchResultNotification hasContent={hasData} />
                        </WbGridCol>
                    </WbGridRow>
                    {hasData && (
                        <WbGridRow className={classes.headerRow}>
                            <WbGridCol mq1={12} mq2={12} mq3={11} mq4={7} mq5={5} mq6={5}>
                                <SearchResultTable fuelApproval={fuelApproval as FuelApprovalDto} />
                            </WbGridCol>
                        </WbGridRow>
                    )}
                </WbGrid>
            )}
        </>
    );
};

export default SearchResult;
