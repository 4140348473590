import { WbGrid, WbGridCol, WbGridRow, WbIcon } from '@workbench/react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useTranslation } from '../../../common/i18n/useTranslation';

const useStyles = makeStyles<{ hasContent: boolean }>()((theme: Theme, { hasContent }) => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    gridRow: {
        gridGap: `${theme.spacing(0)} !important`,
    },
    icon: {
        ['--size' as string]: theme.spacing(4.5),
    },
    gridIconColumn: {
        textAlign: 'center',
        padding: theme.spacing(3),
        background: hasContent ? theme.palette.success.light : theme.palette.error.light,
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
    },
    gridTextColumn: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        background: hasContent ? theme.palette.success.light : theme.palette.error.light,
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },
}));

interface SearchResultNotificationProps {
    hasContent: boolean;
}

const SearchResultNotification = ({ hasContent }: SearchResultNotificationProps) => {
    const { classes } = useStyles({ hasContent });
    const translate = useTranslation();

    return (
        <WbGrid className={classes.container}>
            <WbGridRow className={classes.gridRow}>
                <WbGridCol mq1={1} mq2={1} mq3={1} mq4={1} mq5={1} mq6={1} className={classes.gridIconColumn}>
                    <WbIcon className={classes.icon} name={hasContent ? 'bds/checkmark-circle/24' : 'bds/warning/24'} />
                </WbGridCol>
                <WbGridCol mq1={11} mq2={11} mq3={10} mq4={6} mq5={4} mq6={4} className={classes.gridTextColumn}>
                    {hasContent
                        ? translate('FluidFinderFinSearchResultNotificationSuccess')
                        : translate('FluidFinderFinSearchResultNotificationError')}
                </WbGridCol>
            </WbGridRow>
            {!hasContent && (
                <WbGridRow>
                    <div style={{ height: '250px' }} />
                </WbGridRow>
            )}
        </WbGrid>
    );
};

export default SearchResultNotification;
