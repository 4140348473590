import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CATEGORY_PROPS, MERCEDES } from '../../../common/Constants';
import { parse as dateParse } from 'date-format-parse';
import { emptyProductSheetObject, IProductSheet } from '../../../interfaces/IProductSheet';
import { getSheetByNumber } from '../../../utility/FetchAPI';
import { HeaderBanner } from '../headerBanner/HeaderBanner';
import { SheetTable } from './SheetTable';
import '../../../common/style/GlobalStyle.css';
import { getCategoryAsGermanString, reduceWidthRemoveMarginInHtml } from '../../../utility/Helper';
import moment from 'moment';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { WbGrid, WbHeading, WbText, WbButton, WbGridRow, WbGridCol } from '@workbench/react';
import { useTranslationContext } from '../../../common/i18n/TranslationProvider';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { SupportedLanguages } from '../../../common/i18n/SupportedLanguages';
import { SheetPdfViewer } from './SheetPdfViewer';
import { CustomDialog } from '../../general/dialog/Dialog';
import { saveAs } from 'file-saver';
import { SkeletonSheetOverview } from './SkeletonSheetOverview';
import { useLoadingContext } from '../context/LoadingContext';

const useStyles = makeStyles()((theme: Theme) => ({
    sheetHeading: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    sheetNoProducts: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    additionalInfo: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    publishDate: {
        color: theme.palette.common.white,
        float: 'right',
    },
    htmlContainer: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(6),
    },
    headerRow: {
        alignItems: 'baseline',
    },
}));

export const SheetOverview = () => {
    const { classes } = useStyles();
    const { langState } = useTranslationContext();
    const translate = useTranslation();
    const [sheet, setSheet] = useState<IProductSheet>(emptyProductSheetObject);
    const [foundProps, setFoundProps]: any = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    let { sheetNumber } = useParams<{ sheetNumber: string }>();
    const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
    const { startLoading, stopLoading } = useLoadingContext();

    useEffect(() => {
        startLoading();
        setIsFetchingData(true);

        window.scrollTo(0, 0);
        if (sheetNumber != null) {
            getSheetByNumber(sheetNumber)
                .then((results: any) => {
                    const loopProps = CATEGORY_PROPS.find((element) => {
                        return (
                            getCategoryAsGermanString(element.description) ===
                            getCategoryAsGermanString(results.data.category)
                        );
                    });

                    results.data = sortingMercedesAtTheTopOfTheList(results.data, MERCEDES);
                    if (results.data.htmlDe) {
                        results.data.htmlDe = reduceWidthRemoveMarginInHtml(results.data.htmlDe);
                    }
                    if (results.data.htmlDe) {
                        results.data.htmlEn = reduceWidthRemoveMarginInHtml(results.data.htmlEn);
                    }
                    setSheet(results.data);
                    setFoundProps(loopProps);
                })
                .finally(() => {
                    stopLoading();
                    setIsFetchingData(false);
                });
        }
    }, [sheetNumber, langState]);

    const sortingMercedesAtTheTopOfTheList = (data: any, filterText: string) => {
        const rowsWithFilterWord = data?.productCollection?.filter(
            (row: any) => row.productName.includes(filterText) || row.description.includes(filterText)
        );
        const rowsWithoutFilterWord = data?.productCollection?.filter(
            (row: any) => !row.productName.includes(filterText) && !row.description.includes(filterText)
        );
        if (rowsWithFilterWord && rowsWithoutFilterWord) {
            data.productCollection = [...rowsWithFilterWord, ...rowsWithoutFilterWord];
        }
        return data;
    };

    const categoryFileName =
        sheet.sheetNumber + '   ' + (langState === SupportedLanguages.de ? sheet.titleDe : sheet.titleEn);

    const confirmDownload = (link: string) => {
        fetch(link)
            .then((res) => {
                // permanent variant to catch an error from empty response with status 200 from BE
                const contentDisposition = res.headers.get('content-disposition');

                if (!res.ok || contentDisposition === null) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.blob();
            })
            .then((blob) => {
                saveAs(blob, `${categoryFileName}.pdf`);
            })
            .catch((err) => console.error(err))
            .finally(() => setOpenDialog(false));
    };

    return !isFetchingData ? (
        <>
            <HeaderBanner image={foundProps?.image} description={foundProps?.description} />
            <WbGrid>
                <WbGridRow className={classes.headerRow}>
                    <WbGridCol mq1={12} mq2={12} mq3={12} mq4={9} mq5={9} mq6={9}>
                        <WbHeading size="m" className={classes.sheetHeading}>
                            {categoryFileName}
                        </WbHeading>
                    </WbGridCol>
                    {
                        <WbGridCol
                            mq1={2}
                            mq2={2}
                            mq3={2}
                            mq4={1}
                            mq5={1}
                            mq6={1}
                            startColMq4={12}
                            startColMq5={12}
                            startColMq6={12}
                        >
                            {!!sheet.pdfLinkDe && !!sheet.pdfLinkEn && (
                                <WbButton variant="primary" theme="dark" size="l" onClick={() => setOpenDialog(true)}>
                                    {translate('Download')}
                                </WbButton>
                            )}
                        </WbGridCol>
                    }
                </WbGridRow>
                {openDialog && (
                    <CustomDialog
                        handleClose={() => setOpenDialog(false)}
                        handleConfirm={() =>
                            confirmDownload(langState === SupportedLanguages.de ? sheet.pdfLinkDe : sheet.pdfLinkEn)
                        }
                        header={translate('Download_confirmation')}
                        content={translate('Update_info')}
                    />
                )}
                {sheet.products === null && sheet.pdfLinkDe === null && sheet.pdfLinkEn === null && (
                    <WbText size="s" className={classes.sheetNoProducts}>
                        {translate('NoProduct')}
                    </WbText>
                )}
                <WbText size="s" className={classes.additionalInfo} strong>
                    {langState === SupportedLanguages.de ? sheet.additionalInfoDe : sheet.additionalInfoEn}
                </WbText>
                {(!sheet?.pdfLinkDe || !sheet?.pdfLinkEn) && (
                    <WbText size="m" className={classes.publishDate}>
                        {translate('LastUpdate')}{' '}
                        {langState === SupportedLanguages.de
                            ? moment(new Date(sheet.publishDate).toISOString()).format('DD.MM.YYYY')
                            : moment(new Date(sheet.publishDate).toISOString()).format('MM/DD/YYYY')}
                    </WbText>
                )}
                {(sheet?.pdfLinkDe || sheet?.pdfLinkEn) && <SheetPdfViewer sheet={sheet} language={langState} />}
                <SheetTable sheet={sheet} language={langState} />
            </WbGrid>
        </>
    ) : (
        <SkeletonSheetOverview />
    );
};
