import { WbGrid, WbGridCol, WbGridRow, WbText } from '@workbench/react';
import OilDropImage from '../../assets/image/oilDrop.jpg';
import { useTranslation } from '../../common/i18n/useTranslation';
import { HeaderBanner } from '../category/headerBanner/HeaderBanner';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import SearchField from '../general/components/SearchField';
import { useEffect, useRef, useState } from 'react';
import { FIN_REGEX } from '../../utility/RegExp';
import SearchResult from './searchResult/SearchResult';
import { getVinVehicleData } from '../../utility/FetchAPI';
import { FuelApprovalDto } from '../../api/models/FuelApprovalDto';
import axios from 'axios';

const useStyles = makeStyles()((theme: Theme) => ({
    fluidFinderVinDesc: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    headerRow: {
        gridGap: `${theme.spacing(0)} !important`,
    },
    search: {
        marginBottom: theme.spacing(10),
    },
}));

const FluidFinder = () => {
    const translate = useTranslation();
    const { classes } = useStyles();
    const inputRef = useRef<HTMLElement>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [searchResultCode, setSearchResultCode] = useState<number | null>();
    const [searchTextHasChangedAfterSearch, setSearchTextHasChangedAfterSearch] = useState<boolean>(false);
    const [fuelApproval, setFuelApproval] = useState<FuelApprovalDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [hasSearched, setHasSearched] = useState<boolean>(false);

    const VIN_LENGTH = 17;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
            setSearchText('');
            setSearchResultCode(undefined);
            setErrorMessage(undefined);
        };
    }, []);

    const handleSearchButtonClick = () => {
        if (searchText.length > 0) {
            setIsLoading(true);
            getVinVehicleData(searchText)
                .then((result) => {
                    const fuelApproval = result.data as FuelApprovalDto;
                    setFuelApproval({ ...fuelApproval, fin: searchText });
                    setSearchResultCode(result.status);
                })
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            setSearchResultCode(error.response.status);
                        } else {
                            setSearchResultCode(null);
                        }
                    } else {
                        setSearchResultCode(null);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    setSearchTextHasChangedAfterSearch(false);
                    setHasSearched(true);
                });
        }
    };

    const handleSearchTextChange = (text: string) => {
        if (text.length <= VIN_LENGTH) {
            setSearchText(text.toUpperCase());
        }
        if (hasSearched) {
            setSearchResultCode(undefined);
            setErrorMessage(undefined);
            setHasSearched(false);
        }
        setSearchTextHasChangedAfterSearch(true);
    };

    const showResultTable = () => {
        if (searchResultCode === undefined) {
            return undefined; //init state, show nothing
        } else if (searchResultCode === 200) {
            return true; // show table
        } else if (searchResultCode === 204 || searchResultCode === 401 || searchResultCode === 404) {
            return false; // dont show table
        } else return null; // show error message
    };

    const hasData = showResultTable();

    useEffect(() => {
        getErrorMessage();
    }, [searchText.length, searchResultCode]);

    const getErrorMessage = () => {
        if (searchText.length === VIN_LENGTH && !FIN_REGEX.test(searchText)) {
            setErrorMessage(translate('FluidFinderFinValidationErrorText'));
        } else if (searchResultCode === 200 || searchResultCode === 204) {
            setErrorMessage(undefined);
        } else if (searchResultCode === 401 || searchResultCode === 404) {
            setErrorMessage(translate('FluidFinderFinValidationDoesntExist'));
        } else if (searchResultCode === null || typeof searchResultCode === 'number') {
            setErrorMessage(translate('search_result_error_try_again'));
        } else {
            setErrorMessage(undefined);
        }
    };

    const renderResult = () => {
        if (!isLoading && !errorMessage && hasData !== undefined) {
            return (
                <WbGridRow className={classes.headerRow}>
                    <WbGridCol>
                        <SearchResult hasData={hasData} fuelApproval={fuelApproval} />
                    </WbGridCol>
                </WbGridRow>
            );
        }
        return (
            <WbGridRow>
                <div style={{ height: '400px' }} />
            </WbGridRow>
        );
    };

    return (
        <>
            <HeaderBanner image={OilDropImage} description={'FluidFinderText'} />
            <WbGrid>
                <WbGridRow className={classes.headerRow}>
                    <WbGridCol
                        mq1={12}
                        mq2={12}
                        mq3={12}
                        mq4={12}
                        mq5={10}
                        mq6={10}
                        className={classes.fluidFinderVinDesc}
                    >
                        <WbText size="l">{translate('FluidFinderDesc')}</WbText>
                        <WbText size="l">{translate('FluidFinderVinDesc')}</WbText>
                    </WbGridCol>
                </WbGridRow>
                <WbGridRow className={classes.search}>
                    <WbGridCol>
                        <SearchField
                            mq1={11}
                            mq2={11}
                            mq3={10}
                            mq4={6}
                            mq5={4}
                            mq6={4}
                            delay={0}
                            maxLength={VIN_LENGTH}
                            label={translate('FluidFinderVehicleIdentificationNumber')}
                            inputRef={inputRef}
                            helperText={translate('FluidFinderFinValidationText')}
                            onSearchTextChange={handleSearchTextChange}
                            onSearchButtonClick={handleSearchButtonClick}
                            disableSearch={!searchTextHasChangedAfterSearch || !FIN_REGEX.test(searchText)}
                            errorMessage={errorMessage}
                            isSearching={isLoading}
                        />
                    </WbGridCol>
                </WbGridRow>
                {renderResult()}
            </WbGrid>
        </>
    );
};

export default FluidFinder;
