import { WbAspectRatio, WbHeading, WbText, WbStage, WbGrid } from '@workbench/react';
import { CategoryDescription } from '../../../common/Constants';
import { useTranslation } from '../../../common/i18n/useTranslation';
import I18nTexts from '../../../common/i18n/texts/I18nTexts';

interface HeaderBannerProps {
    image: any;
    description: CategoryDescription | String;
}

export function HeaderBanner(props: HeaderBannerProps) {
    const translate = useTranslation();

    return (
        <WbGrid>
            <WbStage theme="dark">
                <WbAspectRatio slot="media" ratio="9x4">
                    <img src={props.image == null ? '' : props.image} alt="category header" />
                </WbAspectRatio>
                <WbText tag="h2" size="l">
                    Mercedes-Benz Operating Fluids
                </WbText>
                <WbHeading tag="p" size="xl">
                    {props.description ? translate(props.description as keyof I18nTexts) : ''}
                </WbHeading>
            </WbStage>
        </WbGrid>
    );
}
