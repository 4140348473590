import React, { Suspense } from 'react';
import { MainSkeleton } from '../components/MainSkeleton';
import FluidFinder from '../components/fluidFinder/FluidFinder';

function FluidFinderRoute() {
    return (
        <Suspense fallback={<MainSkeleton />}>
            <FluidFinder />
        </Suspense>
    );
}

export default React.memo(FluidFinderRoute);
